import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'saufen-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopComponent implements OnInit {

  constructor(private deviceDetector: DeviceDetectorService,
  ) { }

  loadAPI?: Promise<any>;

  isDesktop: boolean = this.deviceDetector.isDesktop();


  ngOnInit(): void {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  public loadScript() {
    const script = "https://fortgeh.myspreadshop.at/shopfiles/shopclient/shopclient.nocache.js";
    let isFound = false;
    let scripts = document.getElementsByTagName("script")
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && (scripts[i]!.getAttribute('src')!.includes("loader")
        || scripts[i]!.getAttribute('src')!.includes("myspreadshop"))) {
        isFound = true;
        scripts[i].remove();
      }
    }

    if (!isFound) {
      let dynamicScripts = [script]

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }
}
