import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
} from '@angular/core';
import { DataService } from './data.service';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { from, of, Subscription } from 'rxjs';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { addToList, delay, List, Info, PartyType } from './data';
import { DisWeekDialog } from './dis-week/dis-week.component';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { momentWLocale as moment } from './data';

declare var dtrum: any | undefined;
declare var ons: any;

@Component({
  selector: 'saufen-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  
})
export class AppComponent implements OnInit, OnDestroy {
  fortgeh = 'Fortgeh';

  additionalText?: string;

  cnt = 0;
  lastClickedToolbar?: number;
  message: string = 'Pull down to refresh';

  isDesktop: boolean = this.deviceDetector.isDesktop() && !(window.matchMedia('(display-mode: standalone)').matches || (navigator as any).standalone);

  readonly form: string =
    'https://docs.google.com/forms/d/e/1FAIpQLSdLLWd9LSuar6bwieqn3L-8L5_fHpbhVnV-qHGzF-jnc5myuA/viewform?usp=sf_link';
  readonly insta: string = 'https://www.instagram.com/fortgeh';
  readonly shop: string = `${location.protocol}//${location.host}/${this.isDesktop ? '': 'app/'}shop`;
  readonly mail: string = 'fortgeh@rvtr.media';
  readonly mailto: string = `mailto:${this.mail}`;

  dialogList?: List;

  showFull?: boolean;
  hideTopBar?: boolean;

  private subs: Subscription[] = [];

  private popupOpenSubscription: Subscription | undefined;
  private popupCloseSubscription: Subscription | undefined;
  private initializeSubscription: Subscription | undefined;
  private statusChangeSubscription: Subscription | undefined;
  private revokeChoiceSubscription: Subscription | undefined;
  private noCookieLawSubscription: Subscription | undefined;

  constructor(
    private data: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private ccService: NgcCookieConsentService,
    private cookie: CookieService,
    private update: SwUpdate,
    private deviceDetector: DeviceDetectorService,
    private dialog: MatDialog,
    private title: Title,
  ) {
  }
//<script type="text/javascript" src="https://js-cdn.dynatracelabs.com/jstag/14868fa4215/bf25369kzh/ef4edf89337c0f6e_complete.js" crossorigin="anonymous"></script>
  public loadDt() {
    const script = "https://js-cdn.dynatracelabs.com/jstag/14868fa4215/bf25369kzh/ef4edf89337c0f6e_complete.js";
    let isFound = false;
    let scripts = document.getElementsByTagName("script")
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && (scripts[i]!.getAttribute('src')!.includes("dynatrace")
        || scripts[i]!.getAttribute('src')!.includes("bf25369kzh"))) {
        isFound = true;
        scripts[i].remove();
      }
    }

    if (!isFound) {
      let dynamicScripts = [script]

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = true;
        node.crossOrigin = "anonymous";
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }

  public loadAnalytics() {
    const script = "https://www.googletagmanager.com/gtag/js?id=G-781GFJMGP7";
    let isFound = false;
    let scripts = document.getElementsByTagName("script")
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && (scripts[i]!.getAttribute('src')!.includes("gtag")
        || scripts[i]!.getAttribute('src')!.includes("googletagmanager"))) {
        isFound = true;
        scripts[i].remove();
      }
    }

    if (!isFound) {
      let dynamicScripts = [script]

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }

  clickToolbar(): void {
    const now = Date.now();
    if (
      this.lastClickedToolbar &&
      now - this.lastClickedToolbar < moment(0).add(20, 'seconds').valueOf()
    ) {
      this.cnt++;
      if (this.cnt > 10) {
        this.dialog.open(DebugDialog, {data: {
          $version: this.data.getAppData().pipe(map(appdata => appdata.version)),
          $relApps: ('getInstalledRelatedApps' in window.navigator ? from((navigator as any).getInstalledRelatedApps()) : of(undefined)).pipe(map(o => JSON.stringify(o))),
          $sw: from(navigator.serviceWorker.getRegistration()).pipe(
            map(reg => {
             return JSON.stringify(reg !== undefined ? {scope: reg.scope, i: reg.installing, active: reg.active} : undefined);
            })
          ),
          standalone: (navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches
        }, disableClose: true});
        this.cnt = 0;
      }
    } else {
      this.lastClickedToolbar = now;
      this.cnt = 1;
    }
  }

  ngOnDestroy(): void {
    this.popupOpenSubscription?.unsubscribe();
    this.popupCloseSubscription?.unsubscribe();
    this.initializeSubscription?.unsubscribe();
    this.statusChangeSubscription?.unsubscribe();
    this.revokeChoiceSubscription?.unsubscribe();
    this.noCookieLawSubscription?.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  initList() {
    const now = Date.now();
    const nowPlusWeek = moment(now).add(5, 'days').valueOf();
    this.subs.push(this.data
      .getEvents()
      .pipe()
      .subscribe(async (list) => {
        const dialogList: List = new Map();
        list
          .filter((ev) => ev.vip && ev.vip > 0)
          .filter(
            (ev) =>
              moment(ev.date.end ?? ev.date.start)
                .add(12, 'h')
                .valueOf() > now
          )
          .filter((ev) => ev.date.start - nowPlusWeek < 0)
          .sort((a, b) => a.date.start.valueOf() - b.date.start.valueOf())
          .forEach((ev) => {
            if (ev.vip && ev.vip > 0 && ev.date.start - nowPlusWeek < 0) {
              addToList(dialogList, ev);
            }
          });
        this.dialogList = dialogList;
        this.showDialogIfNeeded(this.dialogList);
      }));
  }

  showListOfRecommended() {
    if (this.dialogList) {
      this.showDialogIfNeeded(this.dialogList, true);
    }
  }

  openDialog(): void {
    this.dialog.open(SubmitDialog);
  }

  showDialogIfNeeded(list: List, forceOpen?: boolean): void {
    delay(forceOpen ? 0 : 2000).then(() => {
      const now = Date.now();
      const lastShownStr = localStorage.getItem('recom');
      const lastShown = lastShownStr ? (JSON.parse(lastShownStr) as number) : 0;
      if (
        forceOpen ||
        (list.size > 0 &&
          moment(now).subtract(20, 'hours').valueOf() - lastShown > 0)
      ) {
        this.dialog.open(DisWeekDialog, {
          maxWidth: '100%',
          maxHeight: '80%',
          panelClass: 'dialogRecommendation',
          data: { list, search: '' },
        });
        localStorage.setItem('recom', JSON.stringify(now));
      }
    });
  }

  isInStandaloneMode = () =>
    'standalone' in window.navigator && (window.navigator as any).standalone;

  isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|ios/.test(userAgent);
  };

  showIosInstall = this.isIos() && !this.isInStandaloneMode();

  ngOnInit(): void {
    //check if browser version supports the api
    if ('getInstalledRelatedApps' in window.navigator) {
      (window.navigator as any).getInstalledRelatedApps().then(console.debug);
    }

    this.subs.push(this.route.queryParams
      .pipe(
        map((param) => param.mode),
      )
      .subscribe((mode: string | PartyType) => {
        if (location.pathname.endsWith("events") && mode) {
          this.additionalText = mode.charAt(0).toUpperCase() + mode.slice(1)
        } else {
          this.additionalText = undefined;
        }
      }))

    this.subs.push(this.router.events.subscribe((ev: any) => {
      if (ev instanceof ActivationStart) {
        const data = ev.snapshot.data;
        this.title.setTitle(data.title);

        this.showFull = data.showFull;
        this.hideTopBar = data.hideTopBar;
      }
    }));
    
    if (JSON.parse(localStorage.getItem('cookie')!) as boolean) {
      console.debug("loading scripts")
      this.loadDt();
      setTimeout(() => dtrum?.enable(), 10000)
      this.loadAnalytics();
    } else {
      dtrum?.disable();
      this.cookie.deleteAll();
    }

    if (this.update.versionUpdates.subscribe((up: any) => {
      if (up.type === "VERSION_DETECTED" || up.type === "VERSION_READY") {
        this.update.activateUpdate().then(location.reload).catch(console.error)
      }
    }))

    if (this.update.isEnabled) {
      this.update.checkForUpdate().then((doIt: any) => {
        if (doIt) {
          const check = window.confirm('Willst du das neue Update?');
          if (check) {
            this.update.activateUpdate().then((update: any) => {
              location.reload();
              console.debug({ msg: 'updated', update });
            }).catch(console.error);
          }
        }
      });
    }

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.debug(event);
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        localStorage.setItem(
          'cookie',
          JSON.stringify(event.status === 'allow')
        );
        if (event.status === 'allow') {
          this.loadDt();
          setTimeout(() => dtrum?.enable(), 10000)
          this.loadAnalytics();
        } else {
          dtrum?.disable();
          this.cookie.deleteAll();
        }
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.initList();
  }

  titleClick() {
    this.router.navigate(['app', 'events'], { queryParamsHandling: "preserve"});
    this.reload();
  }

  reload() {
    this.data.reloadButton.next();
  }

  onAction($event: any) {
    setTimeout(() => {
      $event.done();
    }, 1000);
  }

  onChangeState($event: any) {
    switch ($event.state) {
      case 'initial':
        this.message = 'Pull down to refresh';
        break;
      case 'preaction':
        this.message = 'Release to refresh';
        break;
      case 'action':
        this.message = 'Loading data...';
        this.reload();
        break;
    }
  }
}

@Component({
  selector: 'submit-dialog',
  styles: [
    `
      .buttons {
        width: 100%;
        justify-content: space-between;
        > * {
          width: 50%;
        }
      }
    `,
  ],
  template: `
    <h1 mat-dialog-title>Event einreichen</h1>
    <div mat-dialog-content>
      <p>
        Willst du ein Event einreichen oder vorschlagen? Schreibe uns auf
        Instagram oder per Email!
      </p>
    </div>
    <div mat-dialog-actions class="buttons">
      <a target="_blank" [href]="insta" mat-dialog-close
        ><ons-button modifier="large--quiet">Instagram</ons-button></a
      >
      <a target="_blank" [href]="mailto" mat-dialog-close
        ><ons-button modifier="large--quiet">E-Mail</ons-button></a
      >
    </div>
  `,
})
export class SubmitDialog {
  readonly insta: string = 'https://www.instagram.com/fortgeh';
  readonly mail: string = 'submit@fortgeh.at';
  readonly mailto: string = `mailto:${this.mail}`;
}

@Component({
  selector: 'debug-dialog',
  styles: [
    `
      .buttons {
        width: 100%;
        justify-content: space-between;
        > * {
          width: 50%;
        }
      }
    `,
  ],
  template: `
    <h1 mat-dialog-title>DebugDialog</h1>
    <div mat-dialog-content>
      <p>
        Version: {{(data.$version | async)}}
      </p>
      <p>
        relApps: {{data.$relApps | async}}
        </p>
      <p>
        
        sw: {{data.$sw | async}}
        </p>
      <p>
        standalone: {{data.standalone}}
        
      </p>
      <button (click)="doError()">Throw error</button>
    </div>
    <div mat-dialog-actions class="buttons">
      <button mat-button (click)="onNoClick()" cdkFocusInitial>Ok</button>
    </div>
  `,
})
export class DebugDialog {
  constructor(
    public dialogRef: MatDialogRef<DebugDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Info
  ) {}

  doError(): void {
    throw new Error("test error")
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
