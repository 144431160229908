<div [ngClass]="{'desktop-container': isDesktop, 'bg': isDesktop}">
  <ons-toolbar *ngIf="false" class="desktop-toolbar">
    <div class="right">
      <span *ngIf="!isDesktop || !additionalText">{{fortgeh}}</span><span *ngIf="additionalText"><span *ngIf="!isDesktop"> - </span>{{additionalText}}</span>
    </div>
  </ons-toolbar>
  <div [ngClass]="{'smartphone': isDesktop && !showFull}">
    <div [ngClass]="{'content': isDesktop}">
      <ons-page>
        <ons-toolbar *ngIf="!hideTopBar" (click)="clickToolbar()" class="toolbar-bg mobile-toolbar">
          <div class="center title">
            <span (click)="titleClick()" *ngIf="!isDesktop || !additionalText">{{fortgeh}}</span><span *ngIf="additionalText"><span *ngIf="!isDesktop"> - </span>{{additionalText}}</span>
          </div>
          <div class="right" *ngIf="!isDesktop">
            <button mat-icon-button [matMenuTriggerFor]="menu" style="color: white;">
              <mat-icon>more_vert</mat-icon>
            </button>            
            <mat-menu #menu="matMenu">
              <div class="menu">
                <a queryParamsHandling="preserve" [routerLink]="['app', 'events']"><ons-button modifier="large--quiet">Events</ons-button></a>
                <a queryParamsHandling="preserve" [routerLink]="['app', 'taxi']"><ons-button modifier="large--quiet">Taxi</ons-button></a>
                <a *ngIf="dialogList && dialogList.size > 0" (click)="showListOfRecommended()"><ons-button modifier="large--quiet">Empfehlungen</ons-button></a>
                <a queryParamsHandling="preserve" href="https://fortgeh.at/privacy"><ons-button modifier="large--quiet">Impressum</ons-button></a>
                <a (click)="openDialog()"><ons-button modifier="large--quiet">Event einreichen</ons-button></a>
                <a [href]="insta" target="_blank"><ons-button modifier="large--quiet">Instagram</ons-button></a>
                <a queryParamsHandling="preserve" [href]="shop"><ons-button modifier="large--quiet">Merch Shop</ons-button></a>
              </div>
            </mat-menu>
          </div>
        </ons-toolbar>
        <ons-pull-hook *ngIf="!isDesktop" #pullhook height="64px" threshold-height="128px" (changestate)="onChangeState($event)"
          (action)="onAction($event)" id="pull-hook">
          {{message}}
        </ons-pull-hook>

        <router-outlet></router-outlet>
        <ons-bottom-toolbar *ngIf="false">
          <div class="center"></div>
        </ons-bottom-toolbar>
        <ons-if platform="ios" *ngIf="showIosInstall">
          <div class="iosInstall">
            <div class="text">
              <ons-icon icon="md-close"(click)="showIosInstall = false"></ons-icon>
              <div>To install this web app on your device open this site in Safari and tap the Menu button and then 'Add to Home screen' button</div>
            </div>
            <div class="icons">
              <img src="assets/ios-menu-btn.png"><ons-icon icon="md-arrow-right"></ons-icon><img src="assets/ios-add-btn.png"></div>
          </div>
          </ons-if>
      </ons-page>
    </div>
  </div>
<ng-container *ngIf="isDesktop">

<div class="imprint" >
  <a queryParamsHandling="preserve" [routerLink]="['home']">
    <ons-button >
      Home
    </ons-button>
  </a>
  <a queryParamsHandling="preserve" [routerLink]="['events']">
    <ons-button >
      Events
    </ons-button>
  </a>
  <a queryParamsHandling="preserve" [routerLink]="['taxi']">
    <ons-button >
      Taxi
    </ons-button>
  </a>
  <a queryParamsHandling="preserve" [routerLink]="['privacy']">
    <ons-button >
      Impressum
    </ons-button>
  </a>
  <a queryParamsHandling="preserve" [routerLink]="['shop']">
    <ons-button >
      Merch Shop
    </ons-button>
  </a>
  <a target="_blank" [href]="insta">
    <ons-button >
      Instagram
    </ons-button>
  </a>
  <a (click)="openDialog()">
    <ons-button >
      Event einreichen
    </ons-button>
  </a>
</div>
  <div *ngIf="!showFull" class="onlyPhones left" >
    Funzt besser am Handy ;)<br><br>
    #onlyphones
  </div>
  <div *ngIf="!showFull" class="onlyPhones top" >
    Fortgeh - dein neuer Festlkalender
  </div>
  <div *ngIf="!showFull" class="onlyPhones right" >
    Jetzt als App im Handybrowser herunterladen
  </div>
</ng-container>
</div>