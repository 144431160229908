<div class="imprnt">
    <ons-card>
        <div class="title">
            IMPRESSUM
        </div>
        <div class="content">
            <table style="width: 100%;">
                <tr>
                    <th>
                        Name
                    </th>
                    <td>
                        Kacper Olszanski
                    </td>
                </tr>
                <tr>
                    <th>
                        Tel.
                    </th>
                    <td>
                        +4369919194985
                    </td>
                </tr>
                <tr>
                    <th>
                        Email
                    </th>
                    <td>
                        <a href="mailto:contact@fortgeh.at">contact@fortgeh.at</a>
                    </td>
                </tr>
                <tr>
                    <th>
                        PLZ/Ort	
                    </th>
                    <td>
                        4201 Gramastetten
                    </td>
                </tr>
            </table>
        </div>
    </ons-card>
</div>
    <div>
    <ons-card>
        <div class="title">
            WAS VERSTEHT MAN UNTER COOKIES?
        </div>
        <div class="content">
            <p>
                Ein Cookie ist eine kleine Textdatei, die auf dem Computer, dem Mobiltelefon oder einem anderen Gerät abgelegt wird, wenn eine Webseite geöffnet wird.
            </p>
            <p>
                <a (click)="deleteCookies()">Cookies löschen</a>
            </p>
        </div>
    </ons-card>
    <ons-card>
        <div class="title">
            DATENSCHUTZERKLÄRUNG FÜR DIE NUTZUNG VON DYNATRACE
        </div>
        <div class="content">
            <p>
                Diese Website benutzt Dynatrace, einen Application Performance Management Service der Dynatrace GmbH. Dynatrace verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden verschlüsselt an einen Server von Dynatrace übertragen und dort für einen gewissen Zeitraum gespeichert und analysiert. In bestimmten Fällen kann Ihre IP trotz Anonymisierung an die Server ganz übertragen werden. Jedoch werden diese Daten anonymisiert gespeichert und nur zur Performanceanalyse unserer Applikationen benutzt. Es werden auch Daten unanonymisiert gespeichert um es zu ermöglchen auf Anfrage die Daten bestimmter User zu löschen. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
            </p>
            <p>
                Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://www.dynatrace.com/company/trust-center/policies/">https://www.dynatrace.com/company/trust-center/policies/</a>
            </p>
        </div>
    </ons-card>
    <ons-card>
        <div class="title">
            GOOGLE MAPS
        </div>
        <div class="content">
            <p>
                Diese Webseite verwendet Google Maps für die Darstellung von Karteninformationen. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der Maps-Funktionen durch Besucher der Webseiten erhoben, verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung durch Google können Sie den Datenschutzhinweisen von Google auf <a href="https://www.google.at/intl/de/policies/privacy/">https://www.google.at/intl/de/policies/privacy/</a> entnehmen. Dort können Sie im Datenschutzcenter auch Ihre Einstellungen verändern, so dass Sie Ihre Daten verwalten und schützen können.
            </p>
        </div>
    </ons-card>
    <ons-card>
        <div class="title">
            GOOGLE ANALYTICS
        </div>
        <div class="content">
            <p>
                Soweit Sie Ihre Einwilligung erklärt haben, wird auf dieser Website Google Analytics 4 eingesetzt, ein Webanalysedienst der Google LLC. Verantwortliche Stelle für Nutzer in der EU/ dem EWR und der Schweiz ist Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“).
            </p>
            <h4>Umfang der Verarbeitung
            </h4>
            <p>
                Google Analytics verwendet Cookies, die eine Analyse der Benutzung unserer Webseiten durch Sie ermöglichen. Die mittels der Cookies erhobenen Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
            </p>
            <p>
                Wir nutzen die Funktion User-ID. Mithilfe der User ID können wir einer oder mehreren Sitzungen (und den Aktivitäten innerhalb dieser Sitzungen) eine eindeutige, dauerhafte ID zuweisen und Nutzerverhalten geräteübergreifend analysieren.
            </p>
            <p>
                Wir nutzen Google Signale. Damit werden in Google Analytics zusätzliche Informationen zu Nutzern erfasst, die personalisierte Anzeigen aktiviert haben (Interessen und demographische Daten) und Anzeigen können in geräteübergreifenden Remarketing-Kampagnen an diese Nutzer ausgeliefert werden.
            </p>
            <p>
                Bei Google Analytics 4 ist die Anonymisierung von IP-Adressen standardmäßig aktiviert. Aufgrund der IP-Anonymisierung wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird laut Google nicht mit anderen Daten von Google zusammengeführt.
            </p>
            <div>
                <p>
                    Während Ihres Website-Besuchs wird Ihr Nutzerverhalten in Form von „Ereignissen“ erfasst. Ereignisse können sein:
                </p>
                <ul>
                    <li>Seitenaufrufe</li>
                    <li>Erstmaliger Besuch der Website
                    </li>
                    <li>Start der Sitzung
                    </li>
                    <li>Ihr „Klickpfad“, Interaktion mit der Website
                    </li>
                    <li>Scrolls (immer wenn ein Nutzer bis zum Seitenende (90%) scrollt)
                    </li>
                    <li>Klicks auf externe Links 
                    </li>
                    <li>interne Suchanfragen
                    </li>
                    <li>Interaktion mit Videos
                    </li>
                    <li>Dateidownloads</li>
                    <li>gesehene / angeklickte Anzeigen
                    </li>
                    <li>Spracheinstellung</li>
                </ul>
            </div>
            <div>
                <p>
                    Außerdem wird erfasst:
                </p>
                <ul>
                    <li>Ihr ungefährer Standort (Region)
                    </li>
                    <li>Ihre IP-Adresse (in gekürzter Form)
                    </li>
                    <li>technische Informationen zu Ihrem Browser und den von Ihnen genutzten Endgeräten (z.B. Spracheinstellung, Bildschirmauflösung)
                    </li>
                    <li>Ihr Internetanbieter
                    </li>
                    <li>die Referrer-URL (über welche Website/ über welches Werbemittel Sie auf diese Website gekommen sind)
                    </li>
                </ul>
            </div>

            <p>
                Nähere Informationen zu Nutzungsbedingungen von Google Analytics und zum Datenschutz bei Google finden Sie unter 
                <a href="https://marketingplatform.google.com/about/analytics/terms/de/">https://marketingplatform.google.com/about/analytics/terms/de/</a> 
                und unter 
                <a href="https://policies.google.com/?hl=de">https://policies.google.com/?hl=de</a>.
            </p>
        </div>
    </ons-card>
    <ons-card>
        <div class="title">
            RECHTE DER NUTZER UND LÖSCHUNG VON DATEN
        </div>
        <div class="content">
            <p>
                Nutzer haben das Recht, auf Antrag unentgeltlich Auskunft zu erhalten über die personenbezogenen Daten, die von uns über sie gespeichert wurden.
            </p>
            <p>
                Zusätzlich haben die Nutzer das Recht auf Berichtigung unrichtiger Daten, Widerruf von Einwilligungen, Sperrung und Löschung ihrer personenbezogenen Daten sowie das Recht, im Fall der Annahme einer unrechtmäßigen Datenverarbeitung eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
            </p>
            <p>
                Die bei uns gespeicherten Daten werden gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
            </p>
            <p>
                <a href="mailto:contact@fortgeh.at">contact@fortgeh.at</a>
            </p>
        </div>
    </ons-card>
    <ons-card>
        <div class="title">
            AUSKUNFTSRECHT
        </div>
        <div class="content">
            <p>
                Sie haben jederzeit das Recht auf Auskunft über die bezüglich Ihrer Person gespeicherten Daten, deren Herkunft und Empfänger sowie den Zweck der Speicherung.
            </p>
            <p>
                <a href="mailto:contact@fortgeh.at">contact@fortgeh.at</a>
            </p>
        </div>
    </ons-card>
</div>
