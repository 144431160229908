import { Pipe, PipeTransform } from '@angular/core';
import { GeilsFestl } from './data';

@Pipe({
  name: 'sortFestl'
})
export class SortFestlPipe implements PipeTransform {

  transform(value: GeilsFestl[]): GeilsFestl[] {
    return value.sort((a, b) => {
      let sort = this.isVip(a, b);
      if (sort === 0) {
        return a.name.localeCompare((b.name))
      }

      return sort;
    });
  }

  private isVip(a: GeilsFestl, b: GeilsFestl): number {
    if (a.vip === b.vip) {
      return 0;
    } else if (a.vip && !b.vip) {
      return -1;
    } else if (!a.vip && b.vip) {
      return 1;
    } else {
      return b.vip! - a.vip!;
    }
  }

}
