import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'saufen-redirect-app',
  templateUrl: './redirect-app.component.html',
  styleUrls: ['./redirect-app.component.scss']
})
export class RedirectAppComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private deviceDetector: DeviceDetectorService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {

    const installedRelatedApps = 'getInstalledRelatedApps' in window.navigator 
      ? await (window.navigator as any).getInstalledRelatedApps() : undefined;

    const isDesktop: boolean = this.deviceDetector.isDesktop() && !(window.matchMedia('(display-mode: standalone)').matches || (navigator as any).standalone);

    const standalone = (navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches;
    if (this.platform.ANDROID && !standalone && !installedRelatedApps) {
      this.document.location.assign("https://play.google.com/store/apps/details?id=at.fortgeh.two")
    } else if (this.platform.IOS && !standalone) {
      this.router.navigate(["app", "events"], {queryParamsHandling: 'preserve', replaceUrl: true})
    } else {
      if (isDesktop) {
        this.router.navigate(["events"], {queryParamsHandling: 'preserve', replaceUrl: true})

      } else {
        this.router.navigate(["app", "events"], {queryParamsHandling: 'preserve', replaceUrl: true})
      }
    }
  }

}
