import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'saufen-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {

  constructor(private cookie: CookieService) { }

  ngOnInit(): void {
  }

  deleteCookies() {
    this.cookie.deleteAll();
    localStorage.clear();
    sessionStorage.clear();
    window.alert("Cookies wurden gelöscht");
  }
}
