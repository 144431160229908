import * as moment from "moment";

export const enum PartyType {
  FESTL = "festl",
  CLUB = "club",
  FESTIVAL = "festival"
}

export interface GeilsFestl {
    name: string,
    location: string,
    vip?: number,
    link?: string,
    type: PartyType,
    hide?: boolean,
    date: {
        start: number,
        end?: number ,
    },
    style?: {[attr: string]: string | number}
}

export type List = Map<string | number, MonthItem>; 

export interface MonthItem extends Searchable, TimestampSortable {
    name: string;
    days: Map<string | number, DayItem>;
}

export interface DayItem extends Searchable, TimestampSortable {
    name: string;
    festl: Array<GeilsFestl>;
}

interface Searchable {
    searchText: string;
}

export interface TimestampSortable {
    timestamp: number;
    timestampEnd?: number;
}

export const delay = (ms: number) => {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


export interface Taxi {
    name: string;
    phone?: string | number;
    link?: string;
    location?: string;
}

export const H_IN_MS = (hours: number) => hours * 60 * 60 * 1000

export const H24 = H_IN_MS(24);

export const addToList = (list: List, festl: GeilsFestl): void => {
    if (festl.hide) {
      return;
    }
    const start = moment(festl.date.start);
    const end = festl.date.end ? festl.date.end: undefined;
    const month = `${start.get("year")}-${start.get("M")}`;
    const day = "" + start.toDate().getDate();
    if (!list.has(month)) {
      list.set(month, {
        name: month,
        timestamp: festl.date.start,
        timestampEnd: festl.date.end,
        days: new Map(),
        searchText: "",
      });
    }
    list.get(month)!.searchText += festl.name + festl.location + " ";
    if (!list.get(month)!.days.has(day)) {
        !list.get(month)!.days.set(day, {
          name: day,
          searchText: "",
          timestamp: festl.date.start,
          timestampEnd: festl.date.end,
          festl: []
        });
    }
    list.get(month)!.days.get(day)!.searchText += festl.name + " ";
    list.get(month)!.days.get(day)!.searchText += festl.location + " ";
    list.get(month)!.days.get(day)!.festl.push(festl);
    if (end && festl.type !== PartyType.FESTIVAL) {
      if (end > festl.date.start && end - festl.date.start!.valueOf() >= H24) {
        const festl2 = {...festl};
        festl2.date.start += H24;
        addToList(list, festl2);
      }
    }
}

export type Info = {[key: string]: any | Info};

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (navigator as any).userLanguage || navigator.language || (navigator as any).browserLanguage || 'de';
  }
}
export const momentWLocale = (inp?: moment.MomentInput, strict?: boolean) => {
  const lang = getNavigatorLanguage();
  console.debug({lang})
  moment.locale(lang);
  return moment(inp, strict);
};