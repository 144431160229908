import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty'
})
export class EmptyToUndefinedPipe implements PipeTransform {

  transform(value: string | null | undefined): string | undefined {
    if (!value) {
      return undefined;
    }

    return value.trim().length === 0 ? undefined : value;
  }

}
