import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimAndLowerPipe implements PipeTransform {

  transform(value: string): string {
    return value.trim().toLowerCase();
  }

}
