import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, DebugDialog, SubmitDialog } from './app.component';
import { HomeComponent } from './home/home.component';
import { EventsComponent } from './events/events.component';
import { ImprintComponent } from './imprint/imprint.component';
import { DataService } from './data.service';
import { TaxiComponent } from './taxi/taxi.component';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeDEAT from '@angular/common/locales/de-AT';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmptyToUndefinedPipe } from './empty-to-undefined.pipe';
import { TrimAndLowerPipe } from './trim-and-lower.pipe';
import { SortMapPipe } from './sort-map.pipe';
import { SortFestlPipe } from './sort-festl.pipe';
import { CookieService } from 'ngx-cookie-service';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
  NgcCookieConsentService,
} from 'ngx-cookieconsent';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DisWeekDialog } from './dis-week/dis-week.component';
import { EventListComponent } from './event-list/event-list.component';
import { ShopComponent } from './shop/shop.component';
import { RedirectAppComponent } from './redirect-app/redirect-app.component';
import { EventListFestivalComponent } from './event-list-festival/event-list-festival.component';

const cookieSettings: NgcCookieConsentConfig = {
  enabled: !(JSON.parse(localStorage.getItem('cookie')!) as boolean),
  cookie: {
    domain: 'fortgeh.at', // or 'your.domain.com'
  },
  law: { countryCode: 'AT' },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: 'var(--main-color);',
    },
  },
  theme: 'edgeless',
  type: 'opt-out',
};

registerLocaleData(localeDEAT);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EventsComponent,
    SubmitDialog,
    ImprintComponent,
    TaxiComponent,
    EmptyToUndefinedPipe,
    TrimAndLowerPipe,
    SortMapPipe,
    SortFestlPipe,
    DisWeekDialog,
    EventListComponent,
    ShopComponent,
    DebugDialog,
    RedirectAppComponent,
    EventListFestivalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatMenuModule,
    FormsModule,
    NgcCookieConsentModule.forRoot(cookieSettings),
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    DataService,
    Title,
    { provide: LOCALE_ID, useValue: 'de-AT' },
    CookieService,
    NgcCookieConsentService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
