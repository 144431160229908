import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'saufen-taxi',
  templateUrl: './taxi.component.html',
  styleUrls: ['./taxi.component.scss']
})
export class TaxiComponent implements OnInit {

  constructor(private rest: DataService) { }

  isAndroid: boolean = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;

  taxis = this.rest.getTaxis();

  ngOnInit(): void {
  }

}
