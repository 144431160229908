
<div id="top">
  <ons-search-input id="searchfield" placeholder="Suche" #searchfield></ons-search-input>
  <ng-container *ngIf="mode | async as modeSync">
  <br>

  <ng-container [ngSwitch]="modeSync">
    <ng-container *ngSwitchCase="eventType.festl">
      <saufen-event-list [list]="festlList" [search]="search"></saufen-event-list>

      <div *ngIf="!festlList" id="not-working-very-fake-loading-progress-spinner-xd" class="progress-spinner">
        <ons-progress-circular indeterminate></ons-progress-circular>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="eventType.club">
      <saufen-event-list [list]="clublList" [search]="search"></saufen-event-list>

      <div *ngIf="!clublList" id="not-working-very-fake-loading-progress-spinner-xd" class="progress-spinner">
        <ons-progress-circular indeterminate></ons-progress-circular>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="eventType.festival">
      <saufen-event-list-festival [list]="festivalList" [search]="search"></saufen-event-list-festival>

      <div *ngIf="!festivalList" id="not-working-very-fake-loading-progress-spinner-xd" class="progress-spinner">
        <ons-progress-circular indeterminate></ons-progress-circular>
      </div>
    </ng-container>
  </ng-container>



  <ons-fab *ngIf="!isDesktop" (click)="taxi()" class="fab-b" position="bottom right">
    <ons-icon>
      <span class="iconify taxi" data-icon="mdi:taxi"></span>
    </ons-icon>
  </ons-fab>
  <div *ngIf="!isDesktop" class="tabbar bottomBarPlaceholder" [ngClass]="{'tabbar--material': isAndroid}"></div>
  <div class="tabbar bottomBar" [ngClass]="{'tabbar--material': isAndroid, 'iOS': isIOS, 'desktop': isDesktop}">
    <label (click)="changeMode(eventType.festl)"  class="tabbar__item"  [ngClass]="{'tabbar--material__item': isAndroid}"> 
      <input type="radio" name="tabbar-c" [checked]="(modeSync) === eventType.festl ? 'checked' : null">
      <button class="tabbar__button" [ngClass]="{'tabbar--material__button': isAndroid}">
        <div class="tabbar__label" [ngClass]="{'tabbar--material__label': isAndroid}">Festl</div>
      </button>
    </label>

    <label (click)="changeMode(eventType.club)"  class="tabbar__item"  [ngClass]="{'tabbar--material__item': isAndroid}"> 
      <input type="radio" name="tabbar-c" [checked]="(modeSync) === eventType.club ? 'checked' : null">
      <button class="tabbar__button" [ngClass]="{'tabbar--material__button': isAndroid}">
        <div class="tabbar__label" [ngClass]="{'tabbar--material__label': isAndroid}">Club</div>
      </button>
    </label>

    <label (click)="changeMode(eventType.festival)"  class="tabbar__item"  [ngClass]="{'tabbar--material__item': isAndroid}"> 
      <input type="radio" name="tabbar-c" [checked]="(modeSync) === eventType.festival ? 'checked' : null">
      <button class="tabbar__button" [ngClass]="{'tabbar--material__button': isAndroid}">
        <div class="tabbar__label" [ngClass]="{'tabbar--material__label': isAndroid}">Festival</div>
      </button>
    </label>
  </div>
</ng-container>
</div>