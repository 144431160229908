import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { List } from '../data';

@Component({
  selector: 'saufen-dis-week',
  templateUrl: './dis-week.component.html',
  styleUrls: ['./dis-week.component.scss']
})
export class DisWeekDialog implements OnInit {

  constructor(public dialogRef: MatDialogRef<DisWeekDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {list?: List, search?: string}) { 
    }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
