import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeilsFestl, Taxi, Info, PartyType } from './data';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private readonly url = "https://pfuschdb.fortgeh.at/data/";

  constructor(private http: HttpClient) { }

  reloadButton = new Subject();

  private force?: any;

  getEvents(mode?: PartyType, force?: any): Observable<Array<GeilsFestl>> {
    this.setForceUpdate(force);
    return this.http.get<Array<GeilsFestl>>(this.url, {params: {
      type: mode as string,
      cache: this.force
    }})
  }

  getTaxis(): Observable<Array<Taxi>> {
    return this.http.get<Array<Taxi>>(this.url + "taxi")
  }

  getReloaded() {
    return this.reloadButton.asObservable();
  }

  getAppData(): Observable<Info> {
    return this.http.get<Info>("ngsw.json").pipe(
      map((value) => value.appData as Info)
    )
  }

  setForceUpdate(force?: any) {
    this.force = undefined; //force;
  }
}
