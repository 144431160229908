import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Component({
  selector: 'saufen-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private deviceDetector: DeviceDetectorService, private router: Router) { }

  isDesktop: boolean = this.deviceDetector.isDesktop() && !(window.matchMedia('(display-mode: standalone)').matches || (navigator as any).standalone);


  ngOnInit(): void {
    if (!this.isDesktop) {
      this.router.navigate(["app"], {replaceUrl: true});
    }
  }

}
