import { KeyValue, KeyValuePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TimestampSortable } from './data';

@Pipe({
  name: 'sortMap'
})
export class SortMapPipe implements PipeTransform {

  transform<K, V extends TimestampSortable>(map: KeyValue<K, V>[]): KeyValue<K, V>[] {
    return map.sort((a, b) => a.value.timestamp - b.value.timestamp) 
  }

}
