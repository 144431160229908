<ons-list *ngIf="list">
    <ng-container *ngFor="let monthEntry of (list | keyvalue | sortMap)">
      <ons-list-header *ngIf="!(search | empty) || (monthEntry.value.searchText | trim).indexOf(search | trim) >= 0" class="month">
        {{monthEntry.value.timestamp | date: 'MMMM y'}}
      </ons-list-header>
      <ng-container *ngFor="let dayEntry of (monthEntry.value.days | keyvalue | sortMap)">
        <ons-list-header  *ngIf="!(search | empty) || (dayEntry.value.searchText | trim).indexOf(search | trim) >= 0" class="date">
          {{dayEntry.value.timestamp | date: 'fullDate'}}
        </ons-list-header>
        <ng-container *ngFor="let festl of (dayEntry.value.festl | sortFestl)">
          <ng-container *ngIf="!(search | empty) 
          || (festl.name | trim).indexOf(search | trim) >= 0
          || (festl.location | trim).indexOf(search | trim) >= 0" >
          <ons-list-item *ngIf="!(festl.link | empty)">
            <div class="center">
              <div class="list-item__title" [ngClass]="{'list-item--material__title': isAndroid}">
                {{festl.name}}
              </div>
              <div *ngIf="festl.location" class="list-item__subtitle subtitle"
                [ngClass]="{'list-item--material__subtitle': isAndroid}">
                {{festl.location}}
              </div>
            </div>
            <div *ngIf="false" class="right">
              <div class="list-item__label time" [ngClass]="{'list-item--material__label': isAndroid}">
                  <ng-container>{{festl.date.start | date: 'shortTime'}}</ng-container>
              </div>
            </div>
          </ons-list-item>
          <ons-list-item class="l-item" [ngStyle]="festl.vip && festl.vip > 0 ? (festl.style ?? {}) : {}" *ngIf="(festl.link | empty)"
          modifier="chevron"
          tappable
          (click)="goTo(festl.link!)">
            <div class="center">
              <div class="list-item__title" [ngClass]="{'list-item--material__title': isAndroid}">
                {{festl.name}}
              </div>
              <div *ngIf="festl.location" class="list-item__subtitle subtitle"
                [ngClass]="{'list-item--material__subtitle': isAndroid}">
                {{festl.location}}
              </div>
            </div>
            <div *ngIf="false" class="right">
              <div class="list-item__label time" [ngClass]="{'list-item--material__label': isAndroid}">
                  <ng-container>{{festl.date.start | date: 'shortTime'}}</ng-container>
              </div>
            </div>
          </ons-list-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ons-list>

  <ons-list *ngIf="list && list.size === 0">
    <ons-list-item >
      <div class="center">
        <div class="list-item__title" [ngClass]="{'list-item--material__title': isAndroid}">
          Leider haben wir für diese Kategorie gerade keine bevorstehenden Events :(
        </div>
      </div>
    </ons-list-item>
  </ons-list>