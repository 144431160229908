import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { List, addToList, PartyType, GeilsFestl, momentWLocale as moment } from '../data';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'saufen-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

  isAndroid = this.platform.ANDROID
  isIOS = this.platform.IOS
  isNotMobile = !this.isAndroid && !this.isIOS;

  @ViewChild("searchfield", { static: true })
  searchField: any

  isDesktop: boolean = this.deviceDetector.isDesktop()

  private addToList = addToList;

  readonly eventType = {
    festl: PartyType.FESTL,
    club: PartyType.CLUB,
    festival: PartyType.FESTIVAL
  };

  mode?: Observable<PartyType>
  force?: any;

  checkIfFestlOrClubTime = (): PartyType => {
    const month = moment().month()
    return month < 9 && month > 3 ? PartyType.FESTL : PartyType.CLUB;
  }

  constructor(private data: DataService, private router: Router,
    private platform: Platform,
    private viewPort: ViewportScroller,
    private route: ActivatedRoute,
    private deviceDetector: DeviceDetectorService) {

      this.mode = this.route.queryParams.pipe(
        map((p) => p["mode"] ?? localStorage.getItem("mode") ?? this.checkIfFestlOrClubTime() as PartyType),
        );
      this.subs.push(this.data.getReloaded().subscribe(async(value: any) => {
        this.force = Date.now();
        await this.initLists(value as boolean)
      }))
  }

  search: string = "";

  showAll = false;

  private subs: Subscription[] = [];

  allList?: List = new Map();
  festlList?: List = new Map();
  clublList?: List = new Map();
  festivalList?: List = new Map();

  ngOnInit(): void {
    this.initLists().then(() => {
      this.searchField.nativeElement.oninput = () => {
        this.search = this.searchField.nativeElement.value;
      }
      this.data.reloadButton.next(false);
      if (this.route.snapshot.queryParams["q"]) {
        this.search = this.route.snapshot.queryParams["q"];
        this.searchField.nativeElement.value = this.route.snapshot.queryParams["q"];
      }
    });
  }

  async initLists(nullifyLists: boolean = true): Promise<void> {
    this.showAll = this.route.snapshot.queryParams['showAll'] ? this.route.snapshot.queryParams['showAll'] as boolean : false;
    const [tempFestl, tempClub, tempFestival]: List[] = [{...this.festlList} as List, {...this.clublList} as List, {...this.festivalList} as List];
    if (nullifyLists) {
      this.festlList = undefined;
      this.festivalList = undefined;
      this.clublList = undefined;    
    }
    this.festlList = (await this.initList(this.data.getEvents(PartyType.FESTL, this.force))) ?? tempFestl;
    this.clublList = (await this.initList(this.data.getEvents(PartyType.CLUB, this.force))) ?? tempClub;
    this.festivalList = (await this.initList(this.data.getEvents(PartyType.FESTIVAL, this.force))) ?? tempFestival;
  }

  initList($events: Observable<Array<GeilsFestl>>): Promise<List | undefined> {
    const now = Date.now()

    return new Promise((resolve) => this.subs.push(
      $events.subscribe(async list => {
          const newList: List = new Map();
          list = this.showAll ? list : list.filter(ev => (moment(ev.date.end ?? ev.date.start).add(12, "h").valueOf()) > now)
          list.sort((a, b) => a.date.start.valueOf() - b.date.start.valueOf())
            .forEach(ev => {
            this.addToList(newList, ev)
          });
          resolve(newList.size === 0 ? undefined : newList);
    })));
    
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  taxi() {
    this.router.navigate(["app", "taxi"], { queryParamsHandling: "preserve"})
  }

  changeMode(type: PartyType = this.checkIfFestlOrClubTime()) {
    localStorage.setItem("mode", type)
    this.router.navigate([], {queryParams: {
      ...this.route.snapshot.queryParams,
      mode: type
    }, relativeTo: this.route, replaceUrl: true})
    .then((x) => {
      this.viewPort.scrollToAnchor("top");
    })
  }
}
