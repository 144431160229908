import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsComponent } from './events/events.component';
import { ImprintComponent } from './imprint/imprint.component';
import { HomeComponent } from './home/home.component';
import { TaxiComponent } from './taxi/taxi.component';
import { ShopComponent } from './shop/shop.component';
import { RedirectAppComponent } from './redirect-app/redirect-app.component';


const routes: Routes = [{
  path: "",
  pathMatch: "full",
  redirectTo: "/home",
}, {
  path: "home",
  component: HomeComponent,
  data: {
    showFull: true,
    hideTopBar: true,
    title: "Fortgeh - Dein neuer Festlkalender"
  }
}, 
{
  path: "shop", 
  component: ShopComponent,
  data: {
    title: "Fortgeh - Shop",
    showFull: true
  }
}, {
  path: "events",
  component: EventsComponent,
  data: {
    title: "Fortgeh - Dein neuer Festlkalender - Events"
  }
}, {
  path: "taxi",
  component: TaxiComponent,
  data: {
    title: "Fortgeh - Dein neuer Festlkalender - Taxis"
  }
}, 
{
  path: "privacy", 
  component: ImprintComponent,
  data: {
    title: "Fortgeh - Impressum"
  }
}, {
  path: "shop", 
  component: ShopComponent,
  data: {
    title: "Fortgeh - Shop",
    showFull: true
  }
}, {
  path: "app", 
  data: {
    title: "Fortgeh - Dein neuer Festlkalender"
  },
  children: [
    {
      path: "",
      pathMatch: "full",
      component: RedirectAppComponent,
    },
    {
      path: "shop", 
      component: ShopComponent,
      data: {
        title: "Fortgeh - Shop",
        showFull: true
      }
    }, {
      path: "events",
      component: EventsComponent,
      data: {
        title: "Fortgeh - Dein neuer Festlkalender - Events"
      }
    }, {
      path: "taxi",
      component: TaxiComponent,
      data: {
        title: "Fortgeh - Dein neuer Festlkalender - Taxis"
      }
    }
  ]
}];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
