import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { List } from '../data';

@Component({
  selector: 'saufen-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  constructor(private deviceDetector: DeviceDetectorService) { }

  @Input()
  list?: List;

  @Input()
  search: string = "";

  ngOnInit(): void {
  }

  goTo(link: string): void {
    window.open(link, "_blank");
  }

  isAndroid: boolean = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;

  isDesktop: boolean = this.deviceDetector.isDesktop()
}
