<ons-list>
    <ons-list-header>Taxiliste</ons-list-header>
    <ons-list-item expandable *ngFor="let taxi of (taxis | async)">
        {{taxi.name}}
        <div *ngIf="taxi.location" class="list-item__subtitle subtitle"
              [ngClass]="{'list-item--material__subtitle': isAndroid}">
              {{taxi.location}}
            </div>
            <div class="exp expandable-content">
                <a tappable *ngIf="taxi.link" [href]="taxi.link">{{taxi.link}}</a>
                <a tappable *ngIf="taxi.phone" [href]="'tel:' + taxi.phone">{{taxi.phone}}</a>
            </div>
      </ons-list-item>
</ons-list>